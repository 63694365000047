import { createApp } from 'vue'
import App from './App.vue'
import VueSecureHTML from 'vue-html-secure'

export default function init() {
  const element = document.getElementById('resultList')

  if (element) {
    const app = createApp(App, { ...element.dataset })

    app.config.globalProperties.$safeHTML = VueSecureHTML.safeHTML
    app.config.globalProperties.$escapeHTML = VueSecureHTML.escapeHTML
    app.config.globalProperties.$removeHTML = VueSecureHTML.removeHTML

    app.use(VueSecureHTML)
    app.mount('#resultList')
  }
}
