import { createApp } from 'vue'
import App from './App.vue'
import VueScrollTo from 'vue-scrollto'

export default function init() {
  const element = document.getElementById('faqList')

  if (element) {
    const app = createApp(App, { ...element.dataset })

    app.use(VueScrollTo, {
      container: 'body',
      duration: 500,
      easing: 'ease',
      offset: 0,
      force: true,
      cancelable: true,
      onStart: false,
      onDone: false,
      onCancel: false,
      x: false,
      y: true
    })
    app.mount('#faqList')
  }
}
