import { createApp } from 'vue'
import App from './App.vue'

export default function init() {
  function createGoogleMap(element) {
    const app = createApp(App, { ...element.dataset })

    app.mount(`#${element.id}`)
  }

  const maps = document.querySelectorAll('.googleMaps')
  if (maps) {
    maps.forEach((map) => {
      if (map.classList.contains('googleMapsInTab')) {
        return
      }

      createGoogleMap(map)
    })
  }

  window.initGoogleMapsInContentTabs = () => {
    const containers = document.querySelectorAll('.ContentTabs__container')

    containers.forEach((container) => {
      const maps = container.querySelectorAll('.googleMaps')

      maps.forEach((map) => {
        const modifiedId = `${map.id}-in-tab`
        map.id = modifiedId
        createGoogleMap(map)
      })
    })
  }
}
