import { createApp } from 'vue'

export default function init() {
  const element = document.getElementById('accordion')

  if (element) {
    const app = createApp({
      props: {
        multipleActive: {
          type: Boolean,
          default: false
        }
      },
      updated() {
        this.reset()
      },
      methods: {
        updateItems(event) {
          const elm = event.currentTarget
          const contentElm = elm.nextElementSibling
          const activeItem = document.querySelector('.Accordion__active')

          if (elm.classList.contains('Accordion__active')) {
            elm.classList.remove('Accordion__active')
            contentElm.style.height = 0
          } else if (activeItem) {
            activeItem.classList.remove('Accordion__active')
            activeItem.nextElementSibling.style.height = 0
            elm.classList.add('Accordion__active')
            contentElm.style.transition = 'height 300ms ease'
            contentElm.style.height = contentElm.scrollHeight + 13 + 'px'
          } else {
            elm.classList.add('Accordion__active')
            contentElm.style.transition = 'height 300ms ease'
            contentElm.style.height = contentElm.scrollHeight + 13 + 'px'
          }
        },
        updateMultipleItems(event) {
          const elm = event.currentTarget
          const contentElm = elm.nextElementSibling

          if (elm.classList.contains('Accordion__active')) {
            elm.classList.remove('Accordion__active')
            contentElm.style.height = 0
          } else {
            elm.classList.add('Accordion__active')
            contentElm.style.transition = 'height 300ms ease'
            contentElm.style.height = contentElm.scrollHeight + 13 + 'px'
          }
        },
        reset() {
          const activeItems = document.querySelectorAll('.Accordion__active')

          for (let item of activeItems) {
            item.classList.remove('Accordion__active')
            item.nextElementSibling.style.height = 0
          }
        }
      }
    })

    app.mount('#accordion')
  }
}
