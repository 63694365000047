<template>
  <div style="position: relative">
    <div v-if="!iframeLoaded && iframeConfig.src" class="preloadFilter">
      <Loading />
    </div>
    <IFrameResizer
      v-if="iframeConfig.src"
      v-show="iframeLoaded"
      :config="iframeConfig"
      class="shopIFrame"
      @loaded="iframeLoaded = true"
    />
  </div>
</template>

<script setup>
import IFrameResizer from '../IFrameResizer/IFrameResizer.vue'
import Loading from '../Loading/Loading.vue'
import CustomEvents from '../../globals/constants/customEvents'
import ShopPostMessages, { ShopQueryActionType } from '../../globals/constants/shopPostMessages'
import 'url-polyfill'
import reactivateOldBasketByUrl from '../../xhr/shopCart/reactivateOldBasketByUrl'
import { onMounted, onUnmounted, onUpdated, ref } from 'vue'

const props = defineProps({
  config: {
    type: Object,
    default: () => {}
  }
})

const messagesToPost = ref([])
const iframeLoaded = ref(false)
const iframeConfig = ref({
  id: 'webShop',
  width: '100%',
  height: '980px',
  src: null
})

const registerPagePath = '/customer/account/create/'
const forgotPasswordPagePath = '/customer/account/forgotpassword/'
const accountConfirmationResendPath = '/customer/account/confirmation/'
const accountPagePath = '/customer/account'
const accountEditPagePath = '/customer/account/edit'
const addressPagePath = '/customer/address'
const cartPagePath = '/checkout/cart/'
const orderHistoryPagePath = '/sales/order/history'
const successPagePath = '/checkout/onepage/success'
const successPagePathCw = '/computopcw/checkout/success'
const confirmNewAccountPage = '/customer/account/confirm/'
const createNewPasswordPage = '/customer/account/createPassword/'

const setSource = () => {
  const queries = window.location.search.substring(1).split('&')
  let actionType = ''
  if (queries) {
    for (const query of queries) {
      if (query.includes(ShopQueryActionType.ACTION_TYPE)) {
        actionType = query.split('=')[1].toLowerCase()
        switch (actionType) {
          case ShopQueryActionType.REGISTER.toLowerCase():
            iframeConfig.value.src = props.config.shopBaseUrl + registerPagePath  + window.location.search
            break
          case ShopQueryActionType.FORGOT_PASSWORD.toLowerCase():
            iframeConfig.value.src = props.config.shopBaseUrl + forgotPasswordPagePath
            break
          case ShopQueryActionType.RESEND_ACTIVIATION_LINK.toLowerCase():
            iframeConfig.value.src = props.config.shopBaseUrl + accountConfirmationResendPath
            break
          case ShopQueryActionType.CREATE_ACCOUNT_CONFIRM.toLowerCase():
            iframeConfig.value.src =
              props.config.shopBaseUrl + confirmNewAccountPage + window.location.search
            break
          case ShopQueryActionType.CREATE_PASSWORD.toLowerCase():
            iframeConfig.value.src =
              props.config.shopBaseUrl + createNewPasswordPage + window.location.search
            break
          case ShopQueryActionType.SUCCESS_PAYMENT.toLowerCase():
            iframeConfig.value.src = props.config.shopBaseUrl + successPagePath
            break
          case ShopQueryActionType.SUCCESS_PAYMENT_CW.toLowerCase():
            var parameterTxIDValue =
              decodeURIComponent(
                (new RegExp('[?|&]' + 'cstrxid' + '=' + '([^&;]+?)(&|#|;|$)').exec(
                  window.location.search
                ) || [null, ''])[1].replace(/\+/g, '%20')
              ).toLowerCase() || null
            var parameterSecretValue =
              decodeURIComponent(
                (new RegExp('[?|&]' + 'secret' + '=' + '([^&;]+?)(&|#|;|$)').exec(
                  window.location.search
                ) || [null, ''])[1].replace(/\+/g, '%20')
              ).toLowerCase() || null
            iframeConfig.value.src =
              props.config.shopBaseUrl +
              successPagePathCw +
              '/?cstrxid=' +
              parameterTxIDValue +
              '&secret=' +
              parameterSecretValue
            break
          case ShopQueryActionType.ACCOUNT.toLowerCase():
            iframeConfig.value.src = props.config.shopBaseUrl + accountPagePath
            break
          case ShopQueryActionType.ACCOUNT_EDIT.toLowerCase():
            iframeConfig.value.src = props.config.shopBaseUrl + accountEditPagePath
            break
          case ShopQueryActionType.ADDRESS.toLowerCase():
            iframeConfig.value.src = props.config.shopBaseUrl + addressPagePath
            break
          case ShopQueryActionType.HISTORY.toLowerCase():
            iframeConfig.value.src = props.config.shopBaseUrl + orderHistoryPagePath
            break
          case ShopQueryActionType.SHOW_CART.toLowerCase():
            iframeConfig.value.src = props.config.shopBaseUrl + cartPagePath
            break
          case ShopQueryActionType.CANCEL_PAYMENT.toLowerCase():
            handleCancelPayment()
            break
          default:
            iframeConfig.value.src = null
        }
      }
    }
  }
}

const postCancelPaymentMessage = () => {
  const iframe = document.getElementById(iframeConfig.value.id)
  if (iframe) {
    const postMessage = () => {
      iframe.contentWindow.postMessage(ShopPostMessages.SHOW_CANCEL_PAYMENT_MESSAGE, '*')
      iframe.removeEventListener('load', postMessage, false)
    }

    iframe.addEventListener('load', postMessage, false)
    unregisterMessageToPost(postCancelPaymentMessage)
  }
}

const handleCancelPayment = () => {
  reactivateOldBasketByUrl(window.reactivateOldBasketUrl)
    .then(() => {
      iframeConfig.value.src = props.config.shopBaseUrl + cartPagePath
      const timeOut = setTimeout(() => {
        window.dispatchEvent(new CustomEvent(CustomEvents.UPDATE_SHOP_CART, null))
        registerMessageToPost(postCancelPaymentMessage)
        clearTimeout(timeOut)
      }, 2000)
    })
    .catch((error) => console.error('Could not reactivate old basket from ShopIframe.', error))
}

const receiveMessage = (event) => {
  const shopBaseUrl = new URL(props.config.shopBaseUrl || location.origin)
  if (
    event.origin === shopBaseUrl.origin &&
    event.data.toUpperCase() === ShopPostMessages.GET_CART
  ) {
    const timeOut = setTimeout(() => {
      window.dispatchEvent(new CustomEvent(CustomEvents.UPDATE_SHOP_CART, null))
      clearTimeout(timeOut)
    }, 2000)
  } else if (
    event.origin === shopBaseUrl.origin &&
    event.data.toUpperCase() === ShopPostMessages.REDIRECT_TO_BASE_URL
  ) {
    window.top.location.href = window.top.location.href.substring(
      0,
      window.top.location.href.lastIndexOf('/')
    )
  } else if (
    event.origin === shopBaseUrl.origin &&
    event.data.toUpperCase().includes(ShopPostMessages.SCROLL_TOP)
  ) {
    const timeOut = setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' })

      clearTimeout(timeOut)
    }, 700)
  }
}

const registerMessageToPost = (messageFunction) => {
  if (messagesToPost.value.indexOf(messageFunction) === -1) {
    messagesToPost.value.push(messageFunction)
  }
}

const unregisterMessageToPost = (messageFunction) => {
  messagesToPost.value = [...messagesToPost.value.filter((message) => message !== messageFunction)]
}
onUpdated(() => {
  if (messagesToPost.value && messagesToPost.value.length) {
    messagesToPost.value.map((message) => message())
  }
})

onMounted(() => {
  setSource()
  window.addEventListener('message', receiveMessage, false)
})

onUnmounted(() => {
  window.removeEventListener('message', receiveMessage, false)
})
</script>
