import axios from 'axios'

const reactivateOldBasketByUrl = (url) => {
  return new Promise((resolve, reject) => {
    axios.defaults.withCredentials = true
    axios({
      url: url,
      method: 'get',
      responseType: 'json'
    })
      .then((response) => {
        if (response && response.data.status === 1) {
          resolve(response.data)
        } else {
          console.error('Could not reactivate old basket.', response)
          reject(response)
        }
      })
      .catch((error) => {
        console.error('Could not reactivate old basket.', error)
        reject(error)
      })
  })
}

export default reactivateOldBasketByUrl
