<template>
  <a @click="$refs.modalTest.openModal()">
    <ImageMagnifier
      :alt="alt"
      :src="imagesrc"
      :zoom-src="imagezoom"
      mask-bg-color="#434140"
      width="100%"
      height="auto"
      mask-height="70"
      mask-width="70"
      :scale="5"
      :orientation="zoomorientation"
    />
  </a>
  <Modal ref="modalTest" :is-modal-open="isModalOpen" @close-modal="closeModal">
    <img :src="currentSrc" alt="" />
  </Modal>
</template>

<script setup>
import Modal from '../../components/Modal/Modal.vue'
import ImageMagnifier from '../../components/ImageMagnifier/ImageMagnifier.vue'

defineProps({
  alt: {
    type: String,
    default: ''
  },
  imagesrc: {},
  imagezoom: {},
  zoomorientation: {
    type: String,
    default: ''
  }
})

let isModalOpen = false
let currentSrc = null

function closeModal() {
  isModalOpen = false
  currentSrc = null
}
</script>
