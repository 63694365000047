<template>
  <form v-if="!showThankyou" :class="$style.container">
    <div class="columns">
      <div class="column">
        <FormSelect
          v-model.trim="v$.formData.fair.$model"
          :label="config.labels.fair.label"
          :error-message="config.labels.fair.errorMessage"
          :error="v$.formData.fair.$error && v$.$dirty && isSubmitted"
          :options="selectOptionsPlain"
          :initial-option="initialValue"
          :disabled="false"
        />
      </div>
    </div>
    <div v-if="!currentFair.minimized" class="columns">
      <div class="column">
        <FormInput
          v-model.trim="v$.formData.company.$model"
          :label="config.labels.company.label"
          :bold-label="true"
          :error-message="config.labels.company.errorMessage"
          :error="v$.formData.company.$error && v$.$dirty && isSubmitted"
          :original-value="
            previousData.company && previousData.company !== '' ? previousData.company : null
          "
          :form-submit-switch="formSubmitSwitch"
        />
      </div>
      <div class="column">
        <FormInput
          v-model.trim="v$.formData.department.$model"
          :label="config.labels.department.label"
          :bold-label="true"
          :error-message="config.labels.department.errorMessage"
          :error="v$.formData.department.$error && v$.$dirty && isSubmitted"
          :original-value="
            previousData.department && previousData.department !== ''
              ? previousData.department
              : null
          "
          :form-submit-switch="formSubmitSwitch"
        />
      </div>
    </div>
    <div v-if="!currentFair.minimized" class="columns">
      <div class="column">
        <FormInput
          v-model.trim="v$.formData.streetNr.$model"
          :label="config.labels.streetNr.label"
          :bold-label="true"
          :error-message="config.labels.streetNr.errorMessage"
          :error="v$.formData.streetNr.$error && v$.$dirty && isSubmitted"
          :original-value="
            previousData.streetNr && previousData.streetNr !== '' ? previousData.streetNr : null
          "
          :form-submit-switch="formSubmitSwitch"
        />
      </div>
      <div class="column">
        <FormInput
          v-model.trim="v$.formData.zipCity.$model"
          :label="config.labels.zipCity.label"
          :bold-label="true"
          :error-message="config.labels.zipCity.errorMessage"
          :error="v$.formData.zipCity.$error && v$.$dirty && isSubmitted"
          :original-value="
            previousData.zipCity && previousData.zipCity !== '' ? previousData.zipCity : null
          "
          :form-submit-switch="formSubmitSwitch"
        />
      </div>
    </div>
    <div v-if="!currentFair.minimized" class="columns">
      <div class="column">
        <FormInput
          v-model="formData.phone"
          :label="config.labels.phone.label"
          :optional-label="config.labels.phone.optionLabel"
          :error="false"
          :original-value="
            previousData.phone && previousData.phone !== '' ? previousData.phone : null
          "
          :form-submit-switch="formSubmitSwitch"
        />
      </div>
      <div class="column">
        <FormInput
          v-model="formData.fax"
          :label="config.labels.fax.label"
          :optional-label="config.labels.fax.optionLabel"
          :error="false"
          :original-value="previousData.fax && previousData.fax !== '' ? previousData.fax : null"
          :form-submit-switch="formSubmitSwitch"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <FormInput
          v-model.trim="v$.formData.email.$model"
          :label="config.labels.email.label"
          :bold-label="true"
          :error-message="config.labels.email.errorMessage"
          :error="v$.formData.email.$error && v$.$dirty && isSubmitted"
          :original-value="
            previousData.email && previousData.email !== '' ? previousData.email : null
          "
          :form-submit-switch="formSubmitSwitch"
        />
      </div>
      <div class="column" />
    </div>
    <div class="columns">
      <div class="column">
        <FormInput
          v-model.trim="v$.formData.numberOfTickets.$model"
          :label="config.labels.numberOfTickets.label"
          :bold-label="true"
          :error-message="config.labels.numberOfTickets.errorMessage"
          :error="v$.formData.numberOfTickets.$error && v$.$dirty && isSubmitted"
          :original-value="
            previousData.numberOfTickets && previousData.numberOfTickets !== ''
              ? previousData.numberOfTickets
              : null
          "
          :form-submit-switch="formSubmitSwitch"
        />
      </div>
      <div class="column" />
    </div>
    <div v-if="!currentFair.minimized" class="columns">
      <div class="column">
        <FormTextarea
          v-model.trim="v$.formData.recipients.$model"
          :label="config.labels.recipients.label"
          :error-message="config.labels.recipients.errorMessage"
          :error="v$.formData.recipients.$error && v$.$dirty && isSubmitted"
          :rows="5"
          :columns="20"
        />
      </div>
      <div class="column">
        <FormTextarea
          v-model.trim="formData.remarks"
          :label="config.labels.remarks.label"
          :optional-label="config.labels.fax.optionLabel"
          :rows="5"
          :columns="20"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column has-text-right-tablet">
        <Button colour="blue" text="Senden" type="submit" :is-form="true" @close="handleSubmit" />
      </div>
    </div>
    <div v-if="serverError" :class="$style.serverError">
      {{ config.serverErrorMessage }}
    </div>
    <ReCaptcha :execute-switch="executeSwitch" :submit="submit" />
  </form>
</template>

<script setup>
import { email, numeric, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import ReCaptcha from '../ReCaptcha/ReCaptcha.vue'
import FormInput from '../FormInput/FormInput.vue'
import FormSelect from '../FormSelect/FormSelect.vue'
import FormTextarea from '../FormTextarea/FormTextarea.vue'
import Button from '../Button/Button.vue'
import axios from 'axios'
import { computed, onMounted, ref, watch } from 'vue'

const props = defineProps({
  config: {
    type: Object,
    default: () => {}
  }
})

const formData = ref({
  fair: '',
  company: '',
  department: '',
  streetNr: '',
  zipCity: '',
  phone: '',
  fax: '',
  email: '',
  numberOfTickets: '',
  recipients: '',
  remarks: '',
  domain: '',
  pageUrl: '',
  token: ''
})
const previousData = ref({})
const isSubmitted = ref(false)
const formSubmitSwitch = ref(false)
const executeSwitch = ref(false)
const showThankyou = ref(false)
const serverError = ref(false)
const currentFair = ref({})

const rules = computed(() => {
  if (currentFair.value && !currentFair.value.minimized) {
    return {
      formData: {
        fair: {
          required
        },
        company: {
          required
        },
        department: {
          required
        },
        streetNr: {
          required
        },
        zipCity: {
          required
        },
        email: {
          required,
          email
        },
        numberOfTickets: {
          required,
          numeric
        },
        recipients: {
          required
        }
      }
    }
  } else {
    return {
      formData: {
        fair: {
          required
        },
        email: {
          required,
          email
        },
        numberOfTickets: {
          required,
          numeric
        }
      }
    }
  }
})

const v$ = useVuelidate(rules, { formData })

const initialValue = computed(() => {
  let value = ''
  let fairId = getParameter('fair')
  if (!fairId && props.config.initialSelectOption) {
    fairId = props.config.initialSelectOption
  }
  if (fairId) {
    const preset = props.config.selectOptions.find(function (obj) {
      return obj.id == fairId
    })
    if (preset) {
      value = preset.name
    } else {
      value = props.config.selectOptions[0].name
    }
  } else {
    value = props.config.selectOptions[0].name
  }
  return value
})

const selectOptionsPlain = computed(() => {
  return props.config.selectOptions.map((x) => x.name)
})

watch (
  () => formData.value.fair,
  (newFair) => {
    currentFair.value = props.config.selectOptions.find((obj) => {
    return obj.name === newFair
  })
  }
)

onMounted(() => {
  formData.value.fair = initialValue.value
})

const handleSubmit = () => {
  serverError.value = false
  v$.value.$touch()
  isSubmitted.value = true
  formSubmitSwitch.value = !formSubmitSwitch.value
  if (!v$.value.$invalid) {
    executeSwitch.value = !executeSwitch.value
  } else {
    previousData.value = { ...formData.value }
  }
}

const submit = (token) => {
  formData.value.formId = currentFair.value.formId
  formData.value.token = token
  formData.value.domain = window.location.origin
  formData.value.pageUrl = window.location.pathname
  formData.value.language = window.currentLanguage
  formData.value.country = window.currentCountry
  axios({
    url: window.exhibitionFormUrl,
    method: 'post',
    data: formData.value,
    responseType: 'json'
  })
    .then((response) => {
      if (response.status === 200) {
        window.open(props.config.thankyouUrl, '_self')
      } else {
        serverError.value = true
      }
    })
    .catch((error) => {
      serverError.value = true
      console.error(error)
    })
}

const getParameter = (param) => {
  var params = window.location.search.substring(1).split('&')

  for (var i = 0; i < params.length; i++) {
    var p = params[i].split('=')
    if (p[0] == param) {
      return decodeURIComponent(p[1])
    }
  }
  return false
}
</script>

<style module lang="scss">
@import './OrderForm.scss';
</style>
